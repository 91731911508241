import { get, post, deletefn } from '@/http/http'

// 版本查询
export const versionGet = p => get('v1/versioninfo/getversioninfo', p)

// 版本新增
export const versionAdd = p => post('v1/versioninfo/addversioninfo', p)

// 版本删除
export const versionDle = p => deletefn('v1/versioninfo/deleteversioninfo', p)

// 查询新版本
export const getVersionInfo = p => get('v1/versioninfo/getversioninfo_pc', p)
