<template>
  <div class="app_cont">
    <div class="app_box">
      <div class="app_logo"></div>
      <div class="app_phone"></div>
      <div class="acoad">
        <img :src="qrcode" alt="" >
      </div>
      <div class="android"><img src="../../assets/img/Android.png" alt=""></div>
      <div class="icoad">
        <img :src="qrcode1" alt="" >
      </div>
      <div class="ios"><img src="../../assets/img/ios.png" alt=""></div>
      <div class="app_remark">Copyright © 2010 - 2020 dazhihui029.com All Rights Reserved 陕ICP备2020015318号</div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode'
import { getVersionInfo } from '@/http/api/version.js'

export default {
  data () {
    return {
      qrcode: '',
      qrcode1: ''
    }
  },
  created () {
    this.setQRCode1('https://itunes.apple.com/cn/app/id1580910602?mt=8')
    this.init()
  },
  methods: {
    init() {
      getVersionInfo ({}).then(res => {
        if (res.status === '1') {
          this.setQRCode(res.data[0].apkpath)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    setQRCode(str) {
      QRCode.toDataURL(str, { width: 170, margin: 1 }).then(res=>{
        this.qrcode = res
      })
    },
    setQRCode1 (str) {
      QRCode.toDataURL(str, { width: 170, margin: 1 }).then(res=>{
        this.qrcode1 = res
      })
    }
  }
}
</script>

<style scoped>
.app_cont {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  scrollbar-width: none;  /*火狐兼容*/
}
.app_cont ::-webkit-scrollbar {
  width: 0px; /* 谷歌兼容 */
}
.app_box {
  background: url('../../assets/img/downbg.png') no-repeat center;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: relative;
}
.app_logo {
  background: url('../../assets/img/downlogo.png') no-repeat center;
  background-size: 80% 80%;
  width: 762px;
  height: 173px;
  position: absolute;
  left: 16%;
  top: 24%;
}
.app_phone {
  background: url('../../assets/img/downmobile.png') no-repeat center;
  position: absolute;
  top: 13%;
  right: 6%;
  background-size: 85% 85%;
  width: 772px;
  height: 776px;
}
.acoad {
  width: 170px;
  height: 170px;
  border: 3px solid rgba(85, 85, 85, 0);
  position: absolute;
  top: 49%;
  left: 20%;
  background: rgba(234, 237, 243, 0.36);
}
.icoad {
  width: 170px;
  height: 170px;
  border: 3px solid rgba(85, 85, 85, 0);
  position: absolute;
  top: 49%;
  left: 35%;
  background: rgba(234, 237, 243, 0.36);
}
.ios {
  position: absolute;
  top: 69%;
  left: 35%;
  padding-left: 10px;
}
.android {
  position: absolute;
  top: 69%;
  left: 20%;
}
.app_remark {
  position: absolute;
  bottom: 6%;
  left: 35%;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
}
.app_text {
  position: absolute;
  top: 73%;
  left: 20%;
}
.app_text span {
  font-size: 18px;
  font-weight: bold;
  color: #FFFFFF;
}
</style>
